import { Table, TableActions } from 'core.frontend';
import * as React from 'react';
import ObservingComponent from '../../misc/components/ObservingComponent';
import Button from './Button';

interface TableHeaderProps {
    table: Table<any>;
    filters?: JSX.Element;
}

class TableHeader extends ObservingComponent<TableHeaderProps> {

    public componentWillMount(): void {
        const { table } = this.props;

        this.registerUpdateObserver(table.observationProvider, [TableActions.RecordsPerPageChanged, TableActions.SearchPhraseChanged]);
    }

    private recordsPerPageChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { table } = this.props;

        const recordsPerPage = parseInt(e.target.value, 10);

        table.setRecordsPerPage(recordsPerPage);
    }

    private searchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { table } = this.props;

        const searchPhrase = e.target.value;

        table.search(searchPhrase);
    }

    public render(): JSX.Element {
        const { table, filters } = this.props;

        return (
            <div className="table-filter">
                <div className="show-entries">
                    {table.pagingEnabled && (
                        <span>
                            <span>Show</span>
                            <select
                                className="form-control"
                                onChange={this.recordsPerPageChanged}
                                value={table.recordsPerPage}>

                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                            </select>
                            <span>Entries</span>
                        </span>
                    )}

                    { table.onExport &&
                        <Button
                            className="btn btn-table-header"
                            onClick={table.onExport}
                            rightIcon="fa-cloud-download-alt"
                            text="Export" />
                    }
                </div>

                {!!filters && filters}

                <div className="filter-group">
                    <label>Search</label>
                    <input
                        className="form-control"
                        onChange={this.searchChanged}
                        value={table.searchPhrase} />
                </div>
            </div>
        );
    }
}

export default TableHeader;
