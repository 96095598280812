import { ParentAccountDetailPage } from 'core.frontend';
import * as React from 'react';
import CardTab from '../../misc/cardTab';
import Card from '../../misc/components/Card';
import ObservingComponent from '../../misc/components/ObservingComponent';
import ParentAccountDetailAdditionalInfoTab from './ParentAccountDetailAdditionalInfoTab';
import ParentAccountDetailAthletesTab from './ParentAccountDetailAthletesTab';
import ParentAccountDetailBalanceTab from './ParentAccountDetailBalanceTab';
import AnchorButton from '../../misc/components/AnchorButton';

interface ParentAccountDetailProps {
    page: ParentAccountDetailPage;
}

class ParentAccountDetail extends ObservingComponent<ParentAccountDetailProps> {

    public componentWillMount(): void {
        const { page } = this.props;

        this.registerUpdateObserver(page.observationProvider);
    }

    public getTabs(): CardTab[] {
        const { page } = this.props;

        return [
            new CardTab('Balance', <ParentAccountDetailBalanceTab parentAccountDetailPage={page} /> ),
            new CardTab('Wrestlers', <ParentAccountDetailAthletesTab accountDetail={page.accountDetail} /> ),
            new CardTab('Additional Info', <ParentAccountDetailAdditionalInfoTab accountDetail={page.accountDetail} /> )
        ];
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <React.Fragment>
                { page.accountDetail &&
                    <Card
                        heading={(
                            <span>
                                <AnchorButton
                                    onClick={page.backToParentAccounts}
                                    leftIcon="fa-chevron-left"/>

                                {page.title}

                                {page.accountDetail.deleted &&
                                    <span className="red"> - DELETED</span>
                                }
                            </span>)}
                        tabs= { this.getTabs() } />
                }
            </React.Fragment>
        );
    }
}

export default ParentAccountDetail;
