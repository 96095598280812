import classNames = require('classnames');
import { HomePage } from 'core.frontend';
import * as React from 'react';
import Button from '../../misc/components/Button';
import SectionHeading from './SectionHeading';
import TournamentSchedule from '../../misc/components/tournaments/TournamentSchedule';

interface HomeProps{
    page: HomePage;
}

class Home extends React.Component<HomeProps> {

    private goToRegistration = (): void => {
        const { page } = this.props;

        page.goToRegistration();
    }

    private goToTop(event: any) {
        window.scrollTo(0,0);
    }

    public render(): JSX.Element {
        const { page } = this.props;

        return (
            <React.Fragment>
                <div className="home-hero">
                    <div className="container cta-container">
                        <h2>
                            <span className="small">We are excited for the upcoming 2024-2025 season. Our season is underway and registration is now closed!</span>
{/*
                            <span className="small">We are excited for the upcoming 2024-2025 season.</span> */}
                            <br/>
                            <br/>
                            <span className="small">Registration have closed</span>

                            {/* <p className="text-center">
                                <Button
                                    className="btn btn-primary"
                                    onClick={this.goToRegistration}
                                    text="Start Registration" />
                            </p> */}
                        </h2>
                    </div>
                </div>

                <div className="container">
                    <div className="text-center">
                        <p>
                            <b>Please read the following important information</b>
                        </p>

                        <div className="mt-1 page-nav">
                            <a href="#tournament_schedule">Tournament Schedule</a>
                            <a href="#banner_tournament_sponsor_sales">Banner and Tournament Sponshors Sales</a>
                            <a href="#about_neosho_wrestling_program">About Neosho Wrestling Program</a>
                            <a href="#who_can_join">Who can join?</a>
                            <a href="#important_dates">Important Dates</a>
                            <a href="#practice_schedule">Practice Schedule</a>
                            <a href="#coaching_staff">Coaching Staff</a>
                            <a href="#club_fees">Club Fees</a>
                            <a href="#fundraising">Fundraising</a>
                            <a href="#scholarships">Scholarships</a>
                            <a href="#volunteer_coaches">Volunteer Coaches</a>
                            <a href="#schedule">Schedule</a>
                            <a></a>
                        </div>
                    </div>
                </div>

                <div
                    className="page_link_locator"
                    id="tournament_schedule">
                </div>

                <div className="container">

                    <SectionHeading>Tournament Schedule</SectionHeading>

                    <p>
                        We are finializing our season schedule now.
                        Once the scheule is set, you'll be able to access it here
                        and sign up for weekly tournaments all online.
                    </p>

                    <TournamentSchedule tournamentSchedule={page.tournamentSchedule} />
                </div>

                <div
                    className="page_link_locator"
                    id="banner_tournament_sponsor_sales">
                </div>

                <div className="container">

                    <SectionHeading>Banner Add Sale</SectionHeading>

                    <p>We will be displaying our team banner at all tournaments that our club attends. Earn credit toward tournament dues, gear and other wrestling related expenses by recruiting sponsors for our banner. Use the form below to enroll a sponsor for our banner and earn credit toward your account.</p>

                    <div className="text-center"><a href="pdf/Neosho Youth Wrestling Banner.pdf" target="_blank">Click here to download the banner sponsorship form</a></div>
                </div>

                <div className="container">
                    <h2 className="mt-3">Neosho Tournament Sponsors</h2>

                    <p>
                        We need help with our home tournament to offset the cost of the awards. There are three levels of sponsorship.
                        <br/>
                        More information at parent meeting
                    </p>

                    <div className="text-center">
                        <a
                            href="pdf/Neosho Youth Wrestling Tournament Sponsor.pdf"
                            target="_blank">
                                Click here to download the tournament sponsorship form
                        </a>
                    </div>
                </div>

                <div className="container">
                    <hr className="mt-1"/>

                    <div
                        className="page_link_locator"
                        id="about_neosho_wrestling_program">
                    </div>

                    <SectionHeading>Neosho Youth Wrestling 2024-2025</SectionHeading>

                    <br/>
                    <div className="text-center">
                        <a
                            href="pdf/Preseason Packet 24-25.pdf"
                            target="_blank">
                                Click here to download the 2024-2025 Preseason Packet
                        </a>
                    </div>
                    <br/>
                    <br/>

                    <div className="flex">
                        <img
                            className="info-image"
                            src="img\2024_Wrestling_Info_Image.png" />
                    </div>

                    <div className="text-center">
                        <img
                            className="mw-100"
                            src="img\Neosho_Wrestling_Heading.png" />
                    </div>
                </div>

                <div className="container">
                    <br/>
                    <br/>
                    <br/>
                    <h6>Coach's Goals for student-athletes for on and off the mat:</h6>
                    <br/>
                    1) Learn to keep your faith and family close in all the things you do. <br/>
                    2) Accept personal accountability for your words, actions, and results - Ownership. <br/>
                    3) Live a healthy life that includes physical activity, good nutrition, relaxation, and spirituality. <br/>
                    4) Work to find, develop, and use passion for meaningful work - Find your Moxie. <br/>
                    5) Adjust and adapt to change, stress, adversity, and successes - understand the value of the right changes. <br/>
                    6) Demonstrate loyalty to people and a persistent commitment to interests and pursuits. <br/>
                    7) Learn to reflect deeply from experiences, and continuously improve oneself in all aspects of living. <br/>
                    8) Learn to control the factors of attitude and effort by being positive and hardworking despite uncontrollable circumstances and adversities.  <br/>
                    9) Learn to set, pursue, and accomplish personal goals. Understand with this process how ownership, reflection, and change can and will allow your actions to be most efficient and effective. <br/>
                    10) Practice humility while developing pride in oneself, team, school, community, and country. <br/>
                </div>

                <div className="container">
                    <br/>
                    <h4>
                        <u><b>Program Goals</b></u>
                    </h4>
                    <b>Buy into the System and Believe “TRUST” in the process</b><br/>
                    <b>Respect</b> the decisions made by the staff - <b>“TRUST” us</b><br/>
                    <b>Embrace “CHANGE(s)”</b> - <i>ALL PEOPLE INVOLVED SHOULD BE PART OF THE EFFORTS FOR IMPROVEMENT</i><br/>
                    Take <b>PRIDE</b> in <b>OUR Program</b> and <b>OUR School</b><br/>
                    <b>Work</b> to build the support and spirit of OUR Program<br/>
                    Have a <b>positive ATTITUDE</b> that reflects to others that WE have class at all times<br/>
                    <b>Communicate</b> concerns or issues appropriately and at the proper times<br/>
                    <b>Support</b> the ENTIRE Program - ALL four levels and ALL the KIDS<br/>
                    <b>Inspire</b> OUR young people to stay focused and have a long-term vision<br/>
                    <b>Recognize and reward</b> extra efforts, desired attitudes, and even the smallest victories<br/>
                    <b>Remain loyal</b> and never lose sight of <b>OUR goals on and off the mat</b> with the choicest that are made<br/>
                    <b>Represent</b> OUR program positively at ALL times - specifically WE need to do better in the classroom<br/>
                    <b>Invest for the BEST</b> We know what it takes to be at the top but we must work to get back there!<br/>

                    <br/>
                    <b>It is a whole lot easier to get somewhere if you know where you are going</b>
                    <br/>
                    <br/>
                    <i>
                        *Goals below are from Coach Phillips. We must have the coaches, students, and parental buy-in & loyalty - So we will allow our
                        teams to discuss and determine their goals for the season as individuals and as a team.
                    </i>
                    <br/>
                    <br/>

                    <h6>
                        <u><b>Suggested High School Team Goals</b></u>
                    </h6>
                    Undefeated Dual Team<br/>
                    Tournament Champions: Neosho & MO Duals<br/>
                    Top 10 at KC Stampede<br/>
                    Team hardware from Winnetonka<br/>
                    Central Ozark Conference Tournament Champions<br/>
                    District Champions, 12 State Qualifiers<br/>
                    8 State Medalists, 5 Academic All-State<br/>
                    4 State Finalists, 3 State Champions<br/>
                    State Runner-up - as we climb back to the TOP Champions<br/>
                    5 National Medalists<br/>
                    <br/>

                    <h6>
                        <u><b>Suggested Junior High Team Goals</b></u>
                    </h6>
                    Undefeated Dual Team<br/>
                    Tournament Champions: Columbus<br/>
                    Top 3 at JH Boys West Central Ozark Conference<br/>
                    <br/>

                    <h6>
                        <u><b>Suggested Youth Team Goals</b></u>
                    </h6>
                    30 State Qualifiers - Boys and Girls<br/>
                    20 State Medalists - Boys and Girls<br/>
                    8 State Finalists, 4 State Champions - Boys and Girls<br/>
                    Top 10 State Team<br/>
                    25+ National Medalists<br/>
                    <br/>
                    <br/>

                    <div className="text-center">
                        <b>KEYS</b> To reaching our goals is for ALL of us to understand & help in the efforts of being UNITED and energy focused on FIGHTING with and for each other…
                        <br/>
                        <b>FINDING OUR MOXIE TOGETHER</b>
                        <br/>
                        <br/>
                        <b>Stay TOGETHER - Trust TOGETHER - CHANGE TOGETHER - Find our MOXIE TOGETHER</b>
                        <br/>
                        <b><i>
                            “People ACTING TOGETHER as a group can accomplish things which no individual acting alone could ever hope to bring about!” FDR
                        </i></b>
                    </div>
                </div>

                <div className="container">
                    <br/>
                    <b>
                        <u>One Program - Four Levels</u> ALL for ONE, ONE for ALL (Youth, Junior High, High School, Callegiate)
                    </b>

                    "Building Champions on &amp; off the mat."
                    &nbsp;&nbsp;
                    High School State Champions 2010, 11, 13, 14, 15, 16, 17, 19, 20

                    <br/>
                    <br/>

                    <b>
                        <u>Philosophy of Neosho Wrestling Program</u>
                    </b>
                    <br/>

                    <b>Mission Statement: </b> "The Mission of Neosho Wrestling is to build CHAMPIONS on and off the mat." Champions are defined by our character, not particularly gold medals.  <b>Our vision</b> for the program is to teach each wrestler a solid foundation of fundamentals in all three aspects of training (technical, physical, &amp; mental) while emphasizing understanding and development of our core values (faith, team/FAMILY, work, persistence, loyalty, learning from loss, &amp; humility).  With the help of parents and wrestlers trusting in this process we will accomplish our mission and vision.  With mutual respect and a commitment from all those involved we can and will be prepared for overcoming challenges, accomplishing goals, and finding success in all pursuits throughout the journey of life.

                    <br/>
                    <br/>

                    <b>
                        The System and Coaching Staff
                    </b>
                    <br/>
                    My Belief - you get out of something truly what you put into it.  You harvest what you plant and WE will reap what WE sow.  Everything has a price, even success and it must be paid daily.  A critical key for me is to sell "Our System '' in a way that has all wrestlers and parents believe in the process, buy-in, and invest with their time/efforts.  It is crucial to our program’s success that everyone is committed and works together.  The parental support is a vital component for our wrestlers and OUR program.

                    <br/>
                    <br/>
                    <b>"People acting TOGETHER as a group can accomplish things in which no individual acting alone could ever hope to bring about"</b>
                    <br/>
                    <b>Franklin D. Roosevelt</b>

                    <br/>
                    <br/>
                    Neosho Wrestling is one PROGRAM consisting of four different levels (Youth, Jr. High, High School, and Collegiate).  Each level is coached on the same philosophy, principals, and core values.  Our mentality is All for One – One for All.  Using the strengths of each member we will work hard to maintain a tradition of excellence founded in "OUR roots" – those core values for us are found in the following:  Faith, family, work ethic, persistence, loyalty, learning, and humility - All of which, I believe, to be keys in our success.  With a focus on these throughout our training and development I believe, we can maximize the potential we have as individuals but most importantly as a team/program.  This is done in a systematic and progressive approach to meeting the kids where they are at and using the experience of our advanced wrestlers/coaches to lay a strong foundation in fundamentals which we then build upon gradually.  Our training is based on high percentage fundamentals and understanding pressure and body position more than anything; not fancy, flashy moves.

                    <br/>
                    <br/>
                    We will have high expectations as we train at each level and with all student/athletes from 5 to 18 years of age, boy or girl.  We work hard to gain an edge on our opposition in one, if not all three, aspects of our training triangle - technical, physical, and mental.  Each is important but understanding the power of the mind in developing the other two aspects is critical.  Without discipline and focus of the mind our technique will suffer.  Without mental fortitude/toughness our physical development will not be maximized.  Preparing with a "three birds one stone" approach is something we want our young people to understand - meaning as a wrestler works on the technical side oftentimes they can work at a pace that improves their strength/endurance and with the right mental focus/toughness we build the correct habits and grit to be adequately prepared in all three sides to the training triangle.  This is after a step by step approach is taken for techniques so that all the details are done correctly.  Slow and right is always going to be better than fast and wrong.  We have developed many drills that allow us to work on basic techniques, positions, and pressure that helps us get needed repetitions to develop muscle memory and eventually reaction time/speed.   We are creatures of habit and <b>practice DOES NOT make perfect</b>, it makes permanent.

                    <br/>
                    <br/>
                    <b>Aristotle said, "We are what we repeatedly do.  Excellence, then, is not a singular act, but a habit."</b>

                    <br/>
                    <br/>
                    I am confident we can get our program battling again to be one of the top HS teams in the country.   We have an amazing coaching staff (listed above).  If you ever have a problem or concern please see the coach(es) first.  We do not want confrontation so be respectful and communicate appropriately so we can resolve issues adequately.  We are not perfect but I assure you we will work hard to build a positive relationship with all our wrestlers and keep a "what is best for our kids/program" mindset as we make decisions and train our wrestlers to be their very best.
                    <br/>
                    <br/>
                </div>

                <div className="container">
                    <i>
                        <b>Dear Wrestling Parents,</b>
                        <br/>
                        Welcome to the sport of WRESTLING; a sport like no other.  I believe it is one of the greatest sports ever because of the ways it helps young men/women shape their character, develop self-esteem, improve discipline, and strengthen their will to succeed.  It is a very intense, demanding, and physical sport.  Our practices are designed to prepare us technically, physically, and mentally.  They are never necessarily fun but rather a necessity to develop the habits and toughness that allows each wrestler to compete at their very best and move closer to obtaining their/our goals.  Goal setting and understanding the ingredients necessary to reach goals that are set is one of many things I think is important to our progression as a program.  One of many valuable lessons for our staff is making sure our wrestlers learn the value of our attitude and effort - the only two factors that we always control.  I am confident that if one learns to have a positive attitude, a growth mindset, and learns how powerful it is to give their very best, we will not only be prepared for our toughest competitors, but also the toughest circumstances we face in our life's journey.
                        <br/>
                        <br/>
                        I ask that you support and encourage your child by helping them "believe in the process."  This means helping them understand OUR factors to success, which are in my opinion - a strong work ethic, loyalty, persistence, and learning from failure.  I want each of them to know what they do today matters for tomorrow, failure is not fatal but necessary for growth, giving up is never an option, and being a person that people can count on and trust is priceless.  Practicing humility, understanding we are family, and keeping the faith when things are tough are also key for us.  These core values will be emphasized daily and that is one reason why I believe you will see many positives come from your child's participation in our wrestling program.  Welcome to the Wildcat Wrestling Family!
                    </i>

                    <br/>
                    <br/>
                    <u><b>This year's motto:  "Find your MOXIE"</b></u>
                    <br/>
                    <br/>
                    This motto came to fruition at the end of last season when I was thinking about what we needed
                    to beat our best going into the post season and specifically the state tournament. I had thought
                    about and researched ideas and words and I kept coming back to GRIT & MOXIE. Grit is a word
                    that Maryville uses as their mantra and puts on their shirts and so I didn't think I wanted to go that
                    direction but I also wasn't soldon Moxie. Fast forward a couple of weeks. I was standing against
                    the wall at our district tournament and reflecting on the day in my mind, and then I began visiting
                    with an official that had worked the Class 3 district tournament. He told me about a senior who
                    had broken his arm and had it in a cast but his doctor was allowing him to finish his senior
                    season. They cut his castoff, taped and braced it, and he competed. The official said to me "that
                    is some Moxie!" That's when I knew that was it. I was sold on that word. The more I studied the
                    meaning the more I liked it. That young man who had his cast cut off and went to battle despite
                    the adversity went on to be a state runner-up the following week. I was very impressed and knew
                    then I wanted to help our young people understand this frame of mind and help them find their
                    MOXIE.
                    <br/>
                    <br/>

                    <b>By definition:</b>
                    <br/>
                    [mok-see] Moxie - courageous spirit and determination; perseverance. synonyms: backbone, grit, gumption, guts, fortitude. Strength of mind that enables one to endure adversity with courage.
                    <br/>
                    <br/>
                    I believe it is important to help our student-athletes understand that finding our moxie cannot be a special occasionthing. We don't wait until our arm is broken or the postseason is in front of us. We must workt of inditdaily as we train to maximize our potential and effectively bring our best to our toughest battles even in preparation. We must continue to help our students learn that winning is a momentary triumph, but being a winner is a state of mind. It's not about the medals or the accolades, but about the relentless pursuit of excellence, the courage to face challenges, and the resilience to rise after every fall. A true winner is defined not by the victories, but by the spirit that refuses to be defeated.
                    <br/>
                    <br/>
                    “Our greatest glory is not in never falling, but in rising every time we fall.” Confusious
                    <br/>
                    <br/>

                </div>

                <div
                    className="page_link_locator"
                    id="who_can_join">
                </div>

                <div className="container">

                    <SectionHeading>Who can join Neosho Youth Wrestling?</SectionHeading>

                    <hr/>
                    Must be enrolled in the Neosho School District, grades K-8.

                    <br/>
                    Cannot be 15 years old before January 1, 2025
                </div>

                <div
                    className="page_link_locator"
                    id="important_dates">
                </div>

                <div className="container">
                    <SectionHeading>Important Dates</SectionHeading>

                    <hr/>

                    <b>Parent Meeting</b>

                    <br/>
                    Date: November 12, 2024 at 6:00pm

                    <br/>
                    Carver Elementary School

                    <br/>
                    12350 Norway Rd

                    <br/>
                    Neosho MO, 64850

                    <br/>
                    <br/>
                    <b>First Practice </b>

                    <br/>
                    November 18, 2024

                    <br/>
                    <br/>
                    <b>Pictures</b>
                    <br/>
                    December 5, 2024
                    <br/>
                    Details at parent meeting
                </div>

                <div
                    className="page_link_locator"
                    id="practice_schedule">
                </div>

                <div className="container">
                    <SectionHeading>Practice Schedule</SectionHeading>
                    <hr/>

                    <h6>Structure of Youth Practices</h6>
                    <b>3 Levels - Novice, Intermediate, & Advance</b>
                    <br/>

                    The schedule will change a few times and this is why - the first few weeks we will use our experienced
                    wrestlers to help and guide our novice within the practices. Next set of times will be due to the limited time we
                    have available in the gym and once JH concludes we will modify it again to make it most ideal for training
                    each level most effectively.

                    <br/>
                    <br/>

                    <h6>Phase 1</h6>
                    <b>All Wrestlers</b><br/>

                    11/18 - Monday <br/>
                    11/19 - Tuesday <br/>
                    11/21 - Thursday <br/>
                    11/25 - Monday <br/>
                    11/26 - Tuesday<br/>
                    12/2 - Monday<br/>
                    12/3 - Tuesday <br/>
                    12/5 - Thursday<br/>
                    <br/>

                    <h6>Phase Two</h6>
                    <b>Starting Monday - 12/9</b><br/>
                    Mondays - Intermediate & Adv. 6:00-7:30 pm <br/>
                    Wednesdays - ALL girls only practice 5:30-6:30 pm (optional & extra) <br/>
                    Tue & Thu - Novice 6:00-7:00 pm; Int & Adv 6:30-7:45 pm
                    <br/>
                    <br/>

                    <h6>Phase Three</h6>
                    <b>Starting Monday - 1/6</b><br/>
                    Mondays - Intermediate & Adv. 6:00-7:15 pm<br/>
                    Wednesdays - ALL girls only practice 5:30-6:30 pm (optional & extra)<br/>
                    Tue & Thu - Novice 6:00-7:00 pm; Intermediate 6:00-7:15 pm; Advanced 3:45-5 pm
                    <br/>
                    <br/>
                </div>

                <div
                    className="page_link_locator"
                    id="coaching_staff">
                </div>

                <div className="container">
                    <SectionHeading>Coaching Staff</SectionHeading>
                    <hr/>

                    <b>Program Coordinator</b>
                    <br/>
                    Jeremy Phillips

                    <br/>
                    <br/>
                    <b>HS &amp; JH Boys Head Coach</b>
                    <br/>
                    Jeremy Phillips

                    <br/>
                    <br/>
                    <b>HS &amp; JH Assistant Coaches</b>
                    <br/>
                    Joel Schofield
                    <br/>
                    Cayden Auch
                    <br/>
                    Jokiem Crawford
                    <br/>
                    Clayton Swadley
                    <br/>
                    Austin Hailey (HS volunteer)
                    <br/>
                    Cody Crocker (HS volunteer)
                    <br/>
                    Christian Lopez (HS volunteer)
                    <br/>
                    Haven Pennington (JH volunteer)
                    <br/>
                    <br/>
                    <b>Youth Coaches</b>
                    <br/>
                    Carter Boatright
                    <br/>
                    Cayden Auch
                    <br/>
                    Clayton Swadley
                    <br/>
                    Jacob Fry

                    <br/>
                    <br/>
                    <b>Youth Girls Coach</b>
                    <br/>
                    Aubree Saporito
                    <br/>
                    Katie Simpson
                    <br/>
                    Haven Pennington
                    <br/>
                    <br/>

                </div>

                <div
                    className="page_link_locator"
                    id="club_fees">
                </div>

                <div className="container">

                    <SectionHeading>Club Fees</SectionHeading>
                    <hr/>

                    <br/>
                    $150.00 Per wrestler

                    <br/>
                    <br/>
                    Fees Cover 2 Practice shirts, 1 Tournament Team Shirt, Shorts, USA Wrestling Card, Coaches Fees.

                    <br/>
                    <br/>
                    If a team singlet is needed, we will have them for sale starting at $85.00
                    <br/>
                    Must wear a Neosho singlet during tournaments

                    <br/>
                    Tournament cost is about $25 a tournament.
                </div>

                <div
                    className="page_link_locator"
                    id="fundraising">
                </div>

                <div className="container">
                    <SectionHeading>Fundraising</SectionHeading>
                    <hr/>

                    <b>Banner Sales Form</b>
                    <br/>
                    We will be displaying our team banner at all tournaments that our club attends. Earn credit toward tournament dues, gear and other wrestling related expenses by recruiting sponsors for our banner. Use the form below to enroll a sponsor for our banner and earn credit toward your account.

                    <br/>
                    <br/>
                    <b>Tournament Sponsorship Form</b>
                    <br/>
                    We need help with our home tournament to offset the cost of the awards. There are three levels of sponsorship.
                    <br/>
                    More information at parent meeting
                </div>

                <div
                    className="page_link_locator"
                    id="scholarships">
                </div>

                <div className="container">
                    <SectionHeading>Scholarships</SectionHeading>
                    <hr/>

                    <b>Application</b>
                    <br/>
                    <a href="https://www.theallianceofswmo.org/full-potential-program/">https://www.theallianceofswmo.org/full-potential-program/</a>
                </div>

                <div
                    className="page_link_locator"
                    id="volunteer_coaches">
                </div>

                <div className="container">
                    <SectionHeading>Volunteer Coaches</SectionHeading>

                    <hr/>
                    To sign up for a coaches card, you will have to take the safe sport course, pass the background check, upload a photo, take the bronze course and affiliate with Neosho Youth Wrestling. Volunteer coaches will pay for their USA Wrestling coaches cards, background check and bronze course.

                    <br/>
                    <br/>
                    All valunteer coaches must follow the direction of the coaching staff and club board members. Volunteer coaches can be removed from the club for any reason subject to review by the coaching staff and club board.

                    <br/>
                    <br/>
                    TheMatt.com or https://www.teamusa.org/usa-wrestling
                    <br/>
                    <br/>
                </div>


                <div
                    className="container"
                    id="schedule">

                    <SectionHeading>Schedule</SectionHeading>
                    <hr/>
                    <br/>
                    <img src="img\YouthSchedule.jpeg"
                    style={{width: '100%'}}/>
                </div>
            </React.Fragment>
        );
    }
}

export default Home;
