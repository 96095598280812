import { Table as FontEndTable } from 'core.frontend';
import * as React from 'react';
import SmartTableColumn from '../smartTableColumn';
import TableColumn from '../tableColumn';
import EmptyState from './EmptyState';
import ObservingComponent from './ObservingComponent';
import RecordNotFoundState from './RecordNotFoundState';
import Table from './Table';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import TableSortButton from './TableSortButton';

interface SmartTableProps<T extends {id: number}> {
    table: FontEndTable<T>;
    columns: SmartTableColumn<T>[];
    filters?: JSX.Element;
}

class SmartTable<T extends{id: number}> extends ObservingComponent<SmartTableProps<T>> {

    public componentWillMount(): void {
        const { table } = this.props;

        this.registerUpdateObserver(table.observationProvider);
    }

    private buildColumns = (): TableColumn<T>[] => {
        const { columns, table } = this.props;
        const dumbColumns: TableColumn<T>[] = [];

        for (const column of columns) {
            dumbColumns.push({
                className: column.className,
                width: column.width,
                heading: !column.sortExpression ? column.heading : (
                    <TableSortButton
                        table={table}
                        label={column.heading}
                        sortExpression={column.sortExpression} />
                ) ,
                headingClassName: column.headingClassName,
                cellClassName: column.cellClassName,
                cellTemplate: (item: T, rowIndex: number) =>
                    <React.Fragment>
                        {(!column.hideWhenSaving || (!table.isIndexSaving(rowIndex) && !table.isIndexDeleting(rowIndex))) && (
                            <React.Fragment>{column.cellTemplate(item, rowIndex)}</React.Fragment>
                        )}

                        {column.appendSavingIndicator && table.isIndexSaving(rowIndex) && <span className="saving-indicator"> Saving...</span>}
                    </React.Fragment>
            });
        }

        return dumbColumns;
    }

    public render(): JSX.Element {
        const { table, filters } = this.props;

        return (
            <React.Fragment>
                <TableHeader
                    table={table}
                    filters={filters} />

                { table.isEmptyState ? (
                    <EmptyState />
                ) : table.isNoRecordFoundState ? (
                    <RecordNotFoundState />
                ) : (
                    <Table
                        columns={this.buildColumns()}
                        isLoading={table.isLoading}
                        items={table.items}
                        rowClassName={(rowIndex: number) => table.isIndexDeleting(rowIndex) ? 'deleting' : ''}
                        loadingRowCount={table.recordsPerPage} />
                )}
                <TableFooter table={table} />
            </React.Fragment>
        );
    }
}

export default SmartTable;
